.navbar-toggle {
    background-color: $btn-primary-bg;
    border-radius: 50%;
    border-color: $btn-primary-border;
    display: block;
    float: left;
    text-align: center;
    width: $h-navbar-toggle;
    @include margin(0 10px 0 0);
    @include padding(10px 0);

    &:hover,
    &:active,
    &:active:hover,
    &:focus {
        background-color: darken($brand-primary, 10%);
    }

    .icon-bar {
        background-color: $c-navbar-toggle-icon-bar;
        width: 16px;
        @include margin(null auto);

        + .icon-bar {
            margin-top: 3px;
        }
    }
}

.navbar-brand {
    height: auto;
    
    .header & {
      padding: 0px;
      height: 100%;
      
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .auth & {
        width: 100%;
        display: inline-block;
        float: none;
        // @include padding(0px 0px 55px 0px);
        padding: 0px !important;
        padding-bottom: 55px !important;

        @media (max-height: 660px) {
            @include padding(30px null 25px null);
        }

        @media (max-height: 580px) {
            @include padding(35px null 20px null);
        }

        @media (max-height: 535px) {
            @include padding(20px null 15px null);
        }

        svg {
            height: 100% !important;
            width: 100% !important;

            @media (max-height: 580px) {
                height: 145px;
                width: 175px;
            }

            @media (max-height: 535px) {
                height: 125px;
                width: 150px;
            }
        }
    }
}

.navbar-brand {
  width: 100%;

  .side-menu__menu & {
    height: 65px;
  }

  padding: 0px;

  svg {
    height: 100%;
    width: 100%;
  }

  img {
    height: 46px;
    width: 180px;
  }

  div {
    height: 46px;
    width: 180px;
    border-style: dashed;
    border-color: #9d9d9d;
    color: #9d9d9d;
    font-size: 14px;
    font-weight: 500;
    border-width: 1px;
    text-align: center;
  }
}

.mobile-logo-block {
  padding-top: 65px;
  border-bottom: 1px solid #d9d9da;
  background-color: #FFFFFF;

}

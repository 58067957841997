.btn {
    line-height: $lh-small;
    font-weight: $fw-button;
    font-size: $font-size-large;
    text-align: center;
    @include padding(10px 25px);

    .pull-right > &,
    &.pull-right {
        margin-left: 10px;
    }
    &.pull-left {
        margin-right: 10px;
    }

    .row--thin & {
        @include padding(null 12px);
    }

    .auth & {
        font-size: $fz-auth-button;
    }

    svg {
        margin-right: 12px;
        vertical-align: middle;
        @include size(16px, 16px);
    }

    > span {
        vertical-align: middle;
    }

    &--icon {
        width: $h-button;
        @include padding(8px 0);
    }

    &__icon {
        svg {
            display: block;
            @include margin(null auto);
        }

        &--danger {
            color: $brand-danger;

            &:hover,
            &:active,
            &:active:hover,
            &:focus {
                color: darken($brand-danger, 10%);
            }
        }

        &--gray {
            color: $gray-light;

            &:hover,
            &:active,
            &:active:hover,
            &:focus {
                color: darken($gray-light, 10%);
            }
        }
    }

    &__sign {
        .btn > & {
            margin-right: 12px;
        }
    }

  .btn-primary {
    color: #ffd520;
    background-color: #ff0000;
    border-color: #00ff00;
  }

    &-small {
        @include padding(5px 25px);
    }

    &--small {
        font-size: $font-size-base;
        @include padding(9px 20px);

        + .btn--small {
            margin-left: 10px;
        }
    }

    &-wrapper {
        &--bottom {
            @include position(absolute, null 30px 30px 30px);
        }
    }

    &--wrap {
        white-space: normal;
    }
    
    &--help-icon {
        width: 25px;
        height: 25px !important;
        padding: 3px 0 !important;
        
        span {
            margin-left: -1px;
        }
    }
}

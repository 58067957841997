.auth {
    background-color: #00549c;

    &__header {
        text-align: center;
    }

    a {
        &:hover,
        &:active,
        &:active:hover,
        &:focus {
            text-decoration: none;
        }
        
        color: #ffd520; 
    
    }
    
    .btn {
       background-color: #fff;
       color: #9a9a9a;
    }
    
    body {
    color: #ffd520;
    }
    
    .checkbox-block {
      label{
        color:  #ffd520;
      }

      input:checked+label:before{
        border: 1px solid #ffd520;
      }
      
      label:before {
        border: 1px solid #ffd520;
      }
    }

}

.eulaCheckbox {
    margin-bottom: 10px;
}
 
.google {
    &Button {
        // Kolkas nieko
    }

    &Icon {
        display: inline-block;
        margin-top: -2px;
        margin-left: -21px;
        
        &-mobile {
            svg {
                height: 33px;
                width: 33px;
            }
        }
    }

    &Text {
        // Kolkas nieko
    }
}
.form-control {
    color: $brand-primary;
    font-size: $font-size-large;
    @include padding(null $p-input-horizontal);
}
select.form-control {
    width: 100%;
}

label {
    font-weight: $fw-base;
    font-size: $font-size-large;
}

.control-label {
    color: #ffd520;
    display: block;
    line-height: $lh-small;
    @include padding(null $p-input-horizontal);
    margin-bottom: 10px;
}

.form-group {
    .auth & {
        margin-bottom: $form-group-margin-bottom-auth;

        @media (max-height: 660px) {
            margin-bottom: $form-group-margin-bottom-auth-xs;
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.checkbox-block {
    + .checkbox-block {
        margin-top: 15px;
    }

    > input {
        opacity: 0;
        position: absolute;

        + label {
            font-size: $fz-checkbox-label;
            margin-bottom: 0;
            min-height: $size-checkbox;
            position: relative;
            padding-left: 50px;
            padding-top: 2px;
            cursor: pointer;

            &:before {
                border: 1px solid $bdc-checkbox;
                border-radius: 6px;
                content: '';
                display: inline-block;
                @include size($size-checkbox, $size-checkbox);
                @include position(absolute, 0 null null 0);
            }
        }

        &:disabled {
            + label {
                cursor: default;

                &:before {
                    border-color: $bdc-checkbox-disabled;
                }
            }
        }
    }

    &--small > input {
        + label {
            font-size: $font-size-base;
            line-height: 1.3;
            @include padding(6px null);
        }
    }

    &--checkbox > input {
        &:checked + label:before,
        &:checked:disabled + label:before {
            content: '';
            background-image: url('#{$images}/svg/icon-arrow-bottom-white.svg');
            background-position:  50% 50%;
            background-repeat: no-repeat;
            background-size: 15px 8px;
        }

        &:checked + label:before {
            background-color: $brand-primary;
            border-color: $brand-primary;
        }

        &:disabled + label {
            color: $bdc-checkbox-disabled;
        }
        
        &:disabled + label:before {            
            background-color: $bdc-checkbox-disabled;
            border-color: $bdc-checkbox-disabled;
        }
        
        &:checked:disabled + label:before {
            background-color: $bdc-checkbox-disabled;
            border-color: $bdc-checkbox-disabled;
        }
    }

    &--radio > input {
        + label {
            &:before {
                border-radius: 50%;
            }
        }

        &:checked + label:after {
            background-color: #fff;
            border-radius: 50%;
            content: '';
            @include size($size-radio-checked, $size-radio-checked);
            @include position(absolute, $pos-radio-checked null null $pos-radio-checked);
        }
        &:checked + label:before {
            background-color: $brand-primary;
            border-color: $brand-primary;
        }
    }
}

.errors {
    @extend .list-unstyled;
    color: $brand-danger;
    margin-bottom: ($form-group-margin-bottom/2);
    @include padding(0 $p-input-horizontal);

    &--info {
        color: $gray-light;
        text-align: center;
    }
}

.has-error {
    .checkbox-block {
        &--checkbox > input + label:before {
            border-color: $brand-danger;
            background-color: $state-danger-bg;
        }
    }
}

.js-form-group-toggle {
    display: none;
    margin-bottom: $form-group-margin-bottom;
}

.frame {
    min-height: 100%;
    max-width: $w-page-max;
    position: relative;
    text-align: left;
    @include box-shadow(0 0 3px $gray-light);
    @include margin(null auto);
    @include padding(null 0);

    @media (max-width: $screen-xs-max) {
        padding-top: $h-header;
    }

    &.auth {
        padding-top: 0;
    }

    &.inner {
        background-color: $bg-light;
    }

    .content {
        @include padding($p-content 0 0 0);
    }

    &.popup-visible,
    .popup-visible & {
        overflow: hidden;
        position: relative;
        height: 100%;

    }

    &--dark.inner {
        background-color: transparent;
    }
}

.position_relative {
    position: relative;
}

.button__name {
    height: 30%;
    background-color: $brand-primary;
    color: $brand-secondary;
    font-size: 2.5vh;
    text-align: center;
    @include position(absolute, 0 0 null 0);
    @include border-bottom-radius($border-radius-base);
    @include border-top-radius($border-radius-base);
    border: 2px solid #1f3569;
    &-text {
        line-height: $lh-small;
        @include transform(translateY(-50%));
        @include padding(null 15px);
        @include position(absolute, 50% 0 null 0);
    }    
}